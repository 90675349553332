import React from "react";
import GoogleSignin from "../img/btn_google_signin_dark_pressed_web.png";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

const NavBar = () => {
  const [user] = useAuthState(auth);

  const googleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider);
  };

  const signOut = () => {
    auth.signOut();
  };

  return (
    <nav className="navbar bg-primary sticky-top" data-bs-theme="dark">
      <div className="container-fluid">
        <h1 className="navbar-brand">MoCloud</h1>
        {user ? (
          <button onClick={signOut} className="btn btn-outline-light" type="button">
            Sign Out
          </button>
        ) : (
          <button className="btn">
            <img
              onClick={googleSignIn}
              src={GoogleSignin}
              alt="sign in with google"
              type="button"
            />
          </button>
        )}
      </div>
    </nav>
  );
};

export default NavBar;
