// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrJVkK-_FKA7O0NG_Tj-2mnfbmGC8Ycgk",
  authDomain: "mocloud-79469.firebaseapp.com",
  projectId: "mocloud-79469",
  storageBucket: "mocloud-79469.appspot.com",
  messagingSenderId: "451194329892",
  appId: "1:451194329892:web:233e19c5b62c11dcc7b1b1",
  measurementId: "G-ZCNQMDRLSF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
